// import BootstrapHeader from './BootstrapHeader';
// import CollapseHeader from './CollapseHeader';
// import MdbHeader from './MdbHeader';
import RbHeader from './RbHeader';

// const Header = BootstrapHeader;
// const Header = CollapseHeader;
// const Header = MdbHeader;
const Header = RbHeader;

export default Header;
