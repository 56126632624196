export const name = 'Anton Karpenko';
export const email = 'i@karpolan.com';
export const phone = '+421 919 321 977';
export const address = 'SkyPark, Bratislava, SLOVAKIA';
export const linkedin = 'https://www.linkedin.com/in/karpolan/';
export const facebook = 'https://www.facebook.com/KarpolanOfficial/';
export const twitter = 'https://twitter.com/karpolan';
export const youtube = 'https://www.youtube.com/user/karpolan';
export const instagram = 'https://www.instagram.com/karpolan/';
export const github = 'https://github.com/karpolan';
export const slideshare = 'https://www.slideshare.net/karpolan/';
